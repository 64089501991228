@import '_breakpoints';
@import '_colors';
@import '_fonts';
@import '_swiper-custom';
@import '_tailwind-custom';
@import '_plyr-custom';
@import '_skin';

/* Core Styles */

.App {
  min-height: 100vh;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
}

.swiper {
  height: 100%
}

.swiper-slide {
height: auto !important;
text-align: center;
}
.swiper-content {
height: 100% !important
}

.content {
  // flex: 1 1 0;
  min-height: 100vh;
}

.pcdl-content {
  max-width: 68rem;
}

.pcdl-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.quiz-wrapper{
  position: relative;
}

//Series.js

.series-bg {
  background-attachment: relative;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.series-bg-2 {
  background-attachment: relative;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://pcdl-kingssuite-v2.netlify.app/images/messages/the_power_pursuit_purpose.jpg');
}

.bg-signin {
  background-attachment: relative;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 34rem;
}

.series-bg-container {
  @include sm {
    height: 999rem;
  max-height: calc(100vh - 26.75rem);
  }
}

//Watch.js

.grid-cols-watch {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  @include sm {
    grid-template-columns: 70% 30%;
  }
}

/* Login Styles */
.login-container {
  display: flex;
  justify-content: center;
  height: 100%;
  @include lg {
    align-items: center;
  }
}

.login-container--inner {
  width: 66rem;
  margin-bottom: 3rem;
  margin-top: 4rem;
  // @include lg {
  //   height: 40rem;
  // }
}

/* Quiz Styles */

.answered {
  .correct_answer {
    background-color: #1ea55b;
    color: #fff;
    font-weight: 600;
  }
}

.answered {
  .false_answer {
    background-color: #dc0a0a;
    color: #fff;
  }
}

.api-new{
  position: absolute;
  display: block;
  right: .2rem;
  top: .5rem;
}
/* Adjustment Styles */

.translate-x-hidden {
  transform: translateX(-100%);
}

.centered-axis-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.centered-axis-x {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-axis-y {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

div .flag-nav {
  width: 100%;
  height: 100%;
  background-size: cover;
}


div{
.pcdl-accordion-button:not(.collapsed){
box-shadow: none;
background-color: rgb(38, 38, 38);
color: #fff;
&:after{
  background-image: url("http://localhost:3000/images/icons/chevron-down-solid.svg");
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(97deg) brightness(107%) contrast(101%);}
}

.pcdl-accordion-button{
    color: #fff;
    background-color: rgb(38, 38, 38);
    &:after{
    background-image: url("http://localhost:3000/images/icons/chevron-down-solid.svg");
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(97deg) brightness(107%) contrast(101%);    }
}
}


.collapse {
  visibility: unset;
}

/**
 * Smooth scrolling inside an element
 */
 #my-element {
	scroll-behavior: smooth;
}

/**
 * Smooth scrolling on the whole document
 */
html {
	scroll-behavior: smooth;
  scroll-padding-top: 2rem;
}

.cat-item{
  flex: 1 0 21%; /* explanation below */
}


.slider_block{
  height: 10rem;
width: 10rem;
object-fit: cover;
}

.arrow-shadow-r{
  position: absolute;
right: -2%;
top: 50%;
transform: translate(-50%, -50%);
  &::before{
    box-shadow: 0.625rem 0 2.5rem 2.5rem rgb(38, 38, 38);
    right: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    transition: box-shadow .3s ease;
    width: 0;
    z-index: -1;
  }
}

.arrow-shadow-l{
  position: absolute;
left: 3%;
top: 50%;
z-index: 1;
transform: translate(-50%, -50%);
  &::before{
    box-shadow: 0.625rem 0 2.5rem 2.5rem rgb(38, 38, 38);
    left: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    transition: box-shadow .3s ease;
    width: 0;
    z-index: -1;
  }
}

.message-item{
  width: 17vh;
  @include sm {
  width: 24vh;
  }
}

html
{
  scroll-padding-top: 8rem; /* height of sticky header */
}

.pcdl-player{
  .plyr {
    border-radius: .2rem;
  }
}

@media screen and (max-width: 1024px) {
.hide-bg{
  background-image: none!important;
}
}


.bg-jumbotron{
  background-position: 100%;
}

.bg-interest {
    background-color: rgba(0, 0, 0, 0.9);
    height: 100%;
    width: 100%;
    z-index: 49;
    position: absolute;
}

.interest_overlay{
  display: block;
content: ""; // ::before and ::after both require content
    position: absolute;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #2472d2, #75a6e2);
    opacity: .7;
}

#tidio-chat-iframe{
  margin-bottom: 2rem !important;}
  
  .vjs-poster{
    background-color: #000;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .plyr__volume {
      min-width: auto!important;
      width: auto!important;
      input {
        display: none!important;
      }
  }
  }

  .truncate-video{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .adjust-ul ul {
    max-height: 10rem;
  }


  .bg-solutions{
    background-color: #2eaad2;
    min-height: 100vh;
  }

  .text-solutions{
    color: #2eaad2;
  }

  .onebillionsolutions-wrapper .plyr__poster{
    object-fit: cover!important;
  }

  .collapse{
    display: none;
  }

  .show {
    display: block;
  }

.nav-solutions{
  left: 50%; transform: translate(-50%, -50%);
}

.wordatwork-dropdown{
  top: 2rem!important;
}

.homeSwiper{
  overflow-y: visible;
  overflow-x: clip;
}

  // /* Hide the navigation arrows when hovering over a SwiperSlide */
  // .swiper-slide:hover ~ .swiper-button-prev,
  // .swiper-slide:hover ~ .swiper-button-next {
  //   opacity: 0;
  //   pointer-events: none;
  //   transition: opacity 0.3s ease;
  // }
  
  .first-visible-slide {
    border-left: 2px solid red;
  }
  
  .last-visible-slide {
    border-right: 2px solid blue;
  }
  
.swiper-home-item{
  transition: .1s ease;
  // transition-delay: .5s;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  .swiper-home-item--text{
    display: none;
    position: absolute;
  }
  &:hover{
    img{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .swiper-home-item--text{
      display: flex;
    }
  transform: translate(0, -20%) scale(1.3);
  z-index: 9999;
  }
}

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }


  .scrollbar::-webkit-scrollbar {
    width: 12px;
}

.scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #1d3f17;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #3f7c4a;
}
