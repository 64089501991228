.skeleton{
.swiper-button-prev{
  top: 26px;
}
.swiper-button-next{
  top: 26px;
}
}
.swiper.media {
    overflow: visible;
    margin-bottom: 4.6rem;
    overflow-x: clip;
    @include md {
      margin-bottom: 6rem;
    }
  }
  
  .swiper-button-next {
    height: 100%;
    top: 22px;
    right: 0;
    width: 2rem;
    @include sm {
      width: 2.5rem;
    }
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  
  .swiper-button-prev {
    height: 100%;
    top: 22px;
    left: 0;
    width: 2rem;
    @include sm {
      width: 2.5rem;
    }
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  
  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: #fff;
    font-size: 1.2rem;
    @include sm {
      font-size: 1.4rem;
    }
  }
  
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    pointer-events: unset;
  }

  .swiper-pagination{
    z-index: 0;
  }
  /* Custom pagination bullet styles similar to Netflix */
.swiper-pagination-bullet {
  width: 12px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  border-radius: 0%;
  margin: 0 4px;
  opacity: 1;
  transition: background-color 0.3s, transform 0.3s;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-bottom: 1%;
    bottom: 102%;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap,1px);
}

.swiper-pagination-bullet-active {
  background-color: #fff; /* Netflix red for active bullet */
}

  .jumbotron {
    overflow: hidden;
    .swiper {
      overflow: visible;
    }
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.4rem;
    }
  
    .swiper-slide {
      // width: 88%;
      // max-width: 46rem;
      // @include lg {
      //   max-width: 90rem;
      // }
      // @include md {
      //   width: 92%;
      // }
      // @include xl {
      //   width: 96%;
      // }
      // .swiper-slide--bg {
      //   height: 28rem;
      //   @include md {
      //     height: 40rem;
      //   }
      // }

  
      h1, p, button {
        transition: opacity 0.15s linear 0.3s;
        opacity: 0;
      }
    }
  
    .swiper-slide.swiper-slide-active {
      h1, p, button {
        transition: opacity 0.15s linear 0.3s;
        opacity: 1;
      }
    }
  
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      left: unset;
      // right: 2.4rem;
      bottom: 0.8rem;
      margin-left: 0.125rem;
      margin-right: 0.125rem;
      // @include sm {
      //   right: 4rem;
      // }
      // @include lg {
      //   right: 3rem;
      // }
    }
    .swiper-pagination-bullet {
      opacity: 0.4;
      background: #888;
      transform: scale(1);
      transition: 0.15s all ease;
    }
    .swiper-pagination-bullet-active {
      padding: 0 0.6rem;
      border-radius: 2rem;
      opacity: 1;
      background: #fff;
      transform: scale(1.2);
    }
  }

  .slideText {
    top: 102%;
  }

//   .media{
//   .swiper-slide {
//   width: auto!important;
//   }
// }