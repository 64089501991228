.bg-dark--container {
    background-color: #4d4d4d;
  }
  
  .border-pcdl {
    border-color: #8a4fa1;
  }

  .border-pcdl-2 {
    border-color: #00a0f4;
  }

  .bg-pcdl-4 {
    background-color: #E71D72;
  }
  .bg-pcdl-3 {
    background-color: #5e69b1;
  }

  .bg-pcdl-2 {
    background-color: #8a4fa1;
  }
  
  .bg-pcdl {
    background-color: #00a0f4;
  }

  .pcdl-btn {
    background-color: #00a0f4;
&:hover{
  background-color: #36b4f9;
}
  }

  .pcdl-pink-btn {
    background-color: #E71D72;
    color: white;
  }

  .pcdl-orange-btn {
    background-color: #f97316;
    color: white;
  }

  .pcdl-pink-btn-alt {
    background-color: #fff;
    color: #E71D72;
  }

  .pcdl-pink-border {
    border-color: #E71D72;
  }
  
  .text-pcdl {
    color: #00a0f4;
  }
  .text-pcdl-2 {
    color: #5e69b1
  }

  .text-pcdl-3 {
    color: #8a4fa1;
  }

  .text-pcdl-4 {
    color: #E71D72;
  }

  .text-pcdl-search {
    color: #E71D72;
    font-weight: bold;
  }

  .border-pcdl-4{
    border-color: #E71D72;
  }
  .bg-dark{
    background-color: #111111;
  }