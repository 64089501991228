
.plyr {
    height: 100%;
  }
  .plyr--container {
    max-height: calc(100vh - 169px);
    @include lg {
      height: 46.25vw;
      min-height: 480px;
    }
  }

  .plyr--container-8dom {
    @include lg {
      max-height: calc(100vh - 220px);
      height: 46.25vw;
      min-height: 480px;
    }
  }

  
  .videoJs--container--vid{
    width: 100%;
    height: 100%;
  }
  
  .videoJs--container {
    height: 46.25vw;
    max-height: calc(100vh - 169px);
    @include lg {
      min-height: 480px;
    }
  }

  .plyr--full-ui.plyr--video .plyr__control--overlaid {
    background-color: #00b2ff;
  }

  .pcdl-listen {
    .plyr__controls {
      border-radius: 0.25rem;
    }
  }