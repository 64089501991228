@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat-VariableFont_wght.ttf')
      format('truetype-variations');
    font-weight: 1 999;
  }

  @font-face {
    font-family: MilkyNice;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/MilkyNice.ttf') format('truetype')
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Poppins-Regular.ttf') format('truetype')
  }

body,
// span,
p,
a,
figure,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat;
}

.wordfestKidsFont{
  font-family: MilkyNice;
  font-style: normal;
  font-weight: normal;
}