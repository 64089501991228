.dark .dark\:bg-dark {
    background-color: #111111;
  }
  
.max-w-0 {
    max-width: 0rem; /* 0px */
  }
  .max-w-none {
    max-width: none;
  }
  .max-w-xs {
    max-width: 20rem; /* 320px */
  }
  .max-w-sm {
    max-width: 24rem; /* 384px */
  }
  .max-w-md {
    max-width: 28rem; /* 448px */
  }
  .max-w-lg {
    max-width: 32rem; /* 512px */
  }
  .max-w-xl {
    max-width: 36rem; /* 576px */
  }
  .max-w-2xl {
    max-width: 42rem; /* 672px */
  }
  .max-w-3xl {
    max-width: 48rem; /* 768px */
  }
  .max-w-4xl {
    max-width: 56rem; /* 896px */
  }
  .max-w-5xl {
    max-width: 64rem; /* 1024px */
  }
  .max-w-6xl {
    max-width: 72rem; /* 1152px */
  }
  .max-w-7xl {
    max-width: 80rem; /* 1280px */
  }

  @media (min-width: 1024px) {
  .lg\:max-w-none {
    max-width: none;
  }
}